/**
 * User data returned from the endpoint /VergentCustomer/Details
 */
export interface DetailsResponse {
  customerProfile: ProfileResponse
  customerLoans: CustomerLoanDetailResponse
}

/**
 * User data returned from the endpoint /VergentCustomer/UserProfile
 */
export interface UserProfileResponse {
  type: string | null
  title: string | null
  status: number
  detail: string | null
  instance: string | null
  additionalProp1: string | null
  additionalProp2: string | null
  additionalProp3: string | null
  errorMessage: string | null
  errorType: string | null
  correlationId: string | null
  httpStatusCode: number | null
  customerId: number | null
  email: string
}

/**
 * User data returned from the endpoint /VergentCustomer/Profile
 */
export interface ProfileResponse {
  customerId: number
  companyId: number
  firstName: string | null
  lastName: string | null
  birthDate: string | null
  email: string | null
  isACHOrCardPaymentScheduled: boolean
  isEligibleForNewLoan: boolean
  isEligibleForRefinance: boolean
  addresses: CustomerAddress[]
  phones: CustomerPhone[]
  employers: CustomerEmployer[]
  customerStatus: string
  cards: CustomerCard[]
  banks: CustomerBank[]
  customerMobileProfileId: number
  reactivationOnlineStoreId: number | null
  refinanceLoanModelOptions: RefinanceModelOption[]
  maxTTLLoanAmount: number
  maxInstLoanAmount: number
}

interface RefinanceModelOption {
  loanModelId: number
  loanModelName: string
  minAmount: number
  maxAmount: number
  storeId: number
}

interface CustomerAddress {
  id: number
  type: string | null
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  state: string | null
  stateAbbreviation: string | null
  zip: string | null
  isPrimary: boolean
  moveInDate: string | null
}

interface CustomerPhone {
  id: number
  type: string | null
  number: string | null
  isPrimary: boolean
  isDoNotCall: boolean
  isDoNotText: boolean
}

interface CustomerEmployer {
  id: number
  name: string | null
  payAmount: number
  netPayAmount: number
  payFrequency: string | null
  lastPaydate: string | null
  isPrimaryEmp: boolean
}

interface CustomerBank {
  id: number
  type: string | null
  name: string | null
  accountNumberMasked: string | null
  isPrimary: boolean
  isBankAccountActive: boolean
  isCheckingAccount: boolean
}

/**
 * Card info returned from the endpoint /VergentCustomer/Customer/Cards
 */
export interface CustomerCard {
  id: number
  cardTypeId: number
  cardType: string | null
  accountNumberMasked: string | null
  expirationMonth: number
  expirationYear: number
  isExpired: boolean
  isEligibleForDisbursement?: boolean
  cardReference: string
  isOneTimeDebitCard: boolean
}

/**
 * Reponse from the endpoint /VergentLoans/LoansModelAmounts
 */
export interface LoansModelAmountsResponse {
  customerId: string
  loanModelId: string
  amounts: number[]
  minMaxRanges: {minimum: number; maximum: number}[]
}
/**
 * Response from the endpoint /VergentLoans/GetPaymentAmountRequiredToRefinance
 */
export interface GetPaymentAmountRequiredToRefinanceResponse {
  paymentAmountRequiredToRefinance: number
}
/**
 * Response from the endpoint /VergentLoans/GetRefinanceEstimate
 */
export interface GetRefinanceEstimateResponse
  extends GetPaymentAmountRequiredToRefinanceResponse {
  paymentFrequency: string
  paymentSchedule: PaymentSchedule[]
  apr?: number
  financeCharge?: number
  loanAmount?: number
  firstDueDate?: string
}

/**
 * Object response for /VergentLoans/full
 */
export interface FullProcessInfoModel {
  loanHeaderId: number
  publicLoanId: string
  loanStatus: string
  actualLoanStatus: string
  loanSubStatus: string
  loanSubStatusId: number
  storeBrandName: string
  isInternetStore: boolean
  isRefinance: boolean
  loanTags: string[]
  loanClassTypeId: string
  loanClassTypeAbbrev: string
  amountDue: number
  payoffAmount: number
  owedToBeCurrentAmount: number | undefined
  nextPaymentDate: Date
  isEligibleForRefinance: boolean
  availableCash: number
  availableCredit: number
  originationDate: string
  isACHOrCardPaymentScheduled: boolean
  isInRescindPeriod: boolean
  applicationId: string
  applicationStatus: number
  applicationSourceCategory: string
  applicationSourceName: string
  workflowStatus: string
  isSigningPending: boolean
  isSigningComplete: boolean
  isFundingApproved: boolean
  isFundingSent: boolean
  loanDate: Date
  originalPrincipal: number
  originalFees: number
  principalBalance: number
  pastDueAmount: number
  daysLate: string
  feeBalance: number
  originalFeeApr: number
  nextPaymentScheduleDate: Date
  nextPaymentScheduleAmount: number
  originalBalance: number
  currentBalance: number
  isAutoPayEnabled: boolean
  storeId: number
  servicingStoreNumber: string
  storeState: string
  companyId: number
  loanModelId: number
  loanModelName: string
  paymentSchedule: PaymentSchedule[]
  storeNumber: string
  isTitleLoan: boolean
}

/**
 * Object response for /VergentLoans/loans/{loanId}/Transaction
 */
export interface TransactionModel {
  transactionItemId: number
  businessDate: Date
  typeName: string
  principal: number
  fee: number
  discount: number
  change: number
  total: number
  balance: number
}
/**
 *Response for /VergentLoans/full
 */
export interface CustomerLoanDetailResponse {
  fullProcessInfoModels: FullProcessInfoModel[]
}

/**
 * Query params for /VergentLoans/full
 */
export interface GetFullLoansQueryParams {
  customerId: number
}

/**
 * Object Response for /VergentLoans/Loans/{loanId}/Transaction
 */
export interface LoanTransactionResponse {
  loanTransactionHistoryList: TransactionModel[]
}

/**
 * Object Response for /VergentLoans/Loans/{loanId}/Source/{source}/PaymentSchedule
 */
export interface LoanTransactionHistory {
  id: number
  companyId: number
  hdrId: number
  paymentNumber: number
  startDate: Date
  dueDate: Date
  locDueDate: Date
  autoPaymentDate: Date
  paymentAmount: number
  paidDate: Date | null
  businessPaidDate: Date
  isVoid: number
  deferredDate: Date
  effectiveStartDate: Date
  effectiveEndDate: Date
  systemLoanClassType: number
  disolayDeferredDate: boolean
  paymentStatus: number
}

/**
 * Response for /VergentLoans/Loans/{loanId}/Source/{source}/PaymentSchedule
 */
export interface LoanScheduleDataResponse {
  loanTransactionHistoryLists: LoanTransactionHistory[]
}

/**
 * Loan object
 */
export interface LoanDetailsObject {
  customerLoanData: FullProcessInfoModel
  customerLoanScheduleData: LoanTransactionHistory
}
/**
 * Response for /loans/find-by-id endpoint on Edge Service
 */
export interface CustomerDetailsResponse {
  currentLoanDetails: CurrentLoanDetails
  customerDetails: CustomerDetails
  vergentStoreGoLiveDate: Date | null
}

/**
 * CurrentLoanDetails fetched by /loans/find-by-id endpoint on Edge Service
 * TODO: Remove comments
 */
export interface CurrentLoanDetails {
  // DebitCardInfo?: DebitCard
  // AmountToRescind: number
  amountToBecomeCurrent?: number
  isAutoPaymentEnabled: boolean
  isEligibleForRefinance: boolean
  isRescindable: boolean
  isSigningComplete: boolean
  isSigningPending: boolean
  isRefinance: boolean | undefined
  // LatestTransactionDate: Date
  loanId: number
  loanSchedule: ScheduleItem[]
  // MaxApprovalAmount: number
  nextPaymentAmount: number
  nextPaymentDate: Date
  nextPaymentScheduledDate: Date
  originatingStore: string
  isOnlineStore: boolean
  isServicingStoreOnlineStore: boolean
  publicLoanId: string | undefined
  storeId: string
  servicingStoreNumber: string | undefined
  storeState: string
  loanHeaderId: number | undefined
  loanModelId: number
  originationDate: string | undefined
  originalFeeApr: number
  actualLoanStatus: string
  currentBalance: number
  feeBalance: number
  paymentTransactions: Transaction[]
  todaysPayoffAmount: number
  refinanceApprovalDetails: RefinanceApprovalDetail | null
  reactivationApprovalDetails: ReactivationApprovalDetails[] | null
  operatingStatus: string
  activeLoanFlags: string[]
}

/**
 * Response from /payments/add-debit-url
 */
export interface DebitCardUrl {
  url: string
}

/**
 * For debitCadInfo in currentLoanDetails, fetched by /loans/find-by-id
 */
export interface DebitCard {
  cardType: string
  collateralCreditCardToken: number
  collateralCreditCardTokenLast4: string
  contactId: number
  isFundable: boolean
}

/**
 * For paymentTransactions in currentLoanDetails, fetched by /loans/find-by-id
 * TODO: Remove comments
 */
export interface Transaction {
  transactionid: number
  amount: number
  authorizationType: string
  effectiveDate: Date
  paymentType: string
  postingDate: Date
  // TransactionLocation: string
  transactionType: string
}

/**
 * For loanSchedule in currentLoanDetails
 * TODO: Remove comments
 */
export interface ScheduleItem {
  dueAmount: number
  dueDate: Date
  graceDueDate: Date
  // PeriodNumber: number
}

/**
 * For array of refinanceApprovalDetails which should only ever contain 1 option
 * as it is filtered by the Edge service to return only the product which
 * matches the numberOfPeriods as the current loan product the user has
 */
export interface RefinanceApprovalDetail {
  numberOfPeriods: number
  periodLengthField: string
  paymentFrequencyId: number
  refinanceOptions: RefinanceOption[]
  suggestedFirstPaymentDates: string[]
  hasRefinanceOption: boolean
  hasCashbackOption: boolean
  refiStoreId: number
  refiLoanModelId: number
}

/**
 * For array of Refinance Options available under a product type
 * TODO: Remove comments
 */
export interface RefinanceOption {
  cashbackAmount: number
  // RefinanceLoanAmount: number
  refinanceType: RefinanceType
  requiredToPay: number
  // RequiredToPayAllocations: [
  //   {
  //     amountType: string
  //     createDate: Date
  //     interestStatus: string
  //     locationSurrogateKey: number
  //     transactionAllocationId: number
  //     value: number
  //   },
  // ]
}

/**
 * Refinance Option Types
 */
export enum RefinanceType {
  // REFINANCE_CURRENT = 'CurrentPrincipalBalance',
  // REFINANCE_INCREASE = 'IncreasePrincipalBalance',
  CASHBACK = 'Cashback',
  REFINANCE = 'Refinance',
}

/**
 * Customer Status Types
 */
export enum CustomerStatusType {
  Good = 'Good',
  OnHold = 'On Hold',
}

/**
 * ReactivationApprovalDetails in currentLoanDetails, fetched by /loans/details
 */
export interface ReactivationApprovalDetails {
  numberOfPeriods: number
  approvedAmount: number
  periodLengthField: string
  productId: number // TODO: Review if needed
  suggestedFirstPaymentDates: string[]
}

/**
 * Identification interface from reactivation applicant body
 */
export interface ReactivationIdentification {
  localId: string
  customerIdentificationId: number
  identificationTypeId: number
  number: string
  expirationDate: string
  issuerStateAbbreviation: string
  issueDate: string
  issuer: string | null
  properties: ReactivationProperties[]
}

/**
 * Employment interface for reactivation employment
 */
export interface ReactivationEmployment {
  customerEmployerId: number
  employerName: string
  dateEmployed?: string
  localId?: string
  contactPhoneNumber?: string | null
  contactPhoneNumberExtension?: string | null
  employerAddress?: ReactivationAddress | null
  title?: string | null
  isPrimary?: null
  shift?: string | null
  years?: number | null
  months?: number | null
  properties?: ReactivationProperties[]
}

/**
 * Address interface for reactivation addresses
 */
export interface ReactivationAddress {
  customerAddressId: number
  localId?: string
  typeId?: number
  streetAddress: string
  streetAddress2: string
  city: string
  stateAbbreviation: string
  fundingId?: number | null
  zip: string
  properties?: ReactivationProperties[]
  ownership?: number
  monthlyPayment?: number
  yearsAt?: number
  landlordName?: string | null
  landlordPhone?: string | null
  monthsAt?: number
  isPrimary?: boolean
}

/**
 * Supplementar information for reactivation supplemental information
 */
export interface ReactivationSupplementalInformation {
  maritalStatusId: number
  paydayInLastSixMonths: boolean
}

/**
 * File dropdown for reactivation consent Docs
 */
export interface ReactivationFileDropdownValue {
  id: number
  applicationConsentFieldId: number
  consentActionId: number
  isActive: boolean
}

/**
 *  File application for reactivation consent Docs
 */
export interface ReactivationApplicationConsentDoc {
  id: number
  companyId: number
  sectionId: number
  fieldName: string
  fieldTxtValue: string
  fieldValue: number
  fieldRequired: number
  fieldDropDownValue: ReactivationFileDropdownValue[]
  fieldType: string
  templateId: number
  isSelected: boolean
}

/**
 * Supplemental application for reactivation
 */
export interface ReactivationSupplementalInformation {
  maritalStatusId: number
  numberOfDependents: number
  modeOfTransportationId: number
  timeInTheCountryId: number
  paydayInLastSixMonths: boolean
  totalMonthlyExpensesExcludingRentAndMortgage: number
}

/**
 * Loan request information for reactivation
 */
export interface ReactivationLoanRequestInformation {
  applicationTypeId: number
  amountRequested: number
  purposeOfLoanId: number
}

/**
 * Reactivation personal property for reactivation
 */
export interface ReactivationPersonalProperty {
  make: string
  model: string
  serialNumber: string
  estimatedValue: number
  condition: number
  addressOne: string
  addressTwo: string
  stateAbbreviation: string
  city: string
  zip: string
  county: string
}

/**
 * Bank interface for reactivation Bank
 */
export interface ReactivationBank {
  customerBankId: number
  localId?: string
  name: string
  routingNumber?: string
  accountNumber: string
  type?: number
  currentBalance?: number | null
  doYouHaveBankAccount?: boolean | null
  accountOpenDate?: string | null
  isPrimary?: boolean
  properties?: ReactivationProperties[]
}

/**
 * Income interface for reactivation income
 */
export interface ReactivationIncome {
  sourceType: number
  frequency: number
  lastPayDate: string
  isDirectDeposit: boolean
  afterTaxAmount: number
  socialSecurityType: number
  dayOfWeek: number
  dayOfMonth1: number
  isMonthlyLastDay: boolean
  dayOfMonth2: number
  isDayOfMonth2Lastday: boolean
  weekOfMonth: number
  sameDaySameWeek: number
  nextPayDate: string
  beforeTaxAmount: number
  netPayAmount: number
  payFrequencyId: number
}

/**
 *Income interface for reactivation card
 */
export interface ReactivationCard {
  localId: string
  last4: string
  token: string
  cardType: number
  expirationDate: string
}

/**
 *Income interface for reactivation Misc
 */
export interface ReactivationMisc {
  deviceBlackbox: string
  biocatchSessionId: string
  hitPathId: string
  xid: string
  ipAddress: string
  originalLeadProvider: string
}

/**
 *Phone interface for reactivation Phones
 */
export interface ReactivationPhoneNumber {
  localId?: string
  number: string
  isPrimary?: boolean
  isVerified?: boolean
  customerPhoneId: number
  typeId?: number
  properties?: ReactivationProperties[]
}

/**
 *Reactivation applicant details
 */
export interface ReactivationApplicantBody {
  _Identification?: ReactivationIdentification
  emailAddress: string
  card?: ReactivationCard | null
  _Card?: ReactivationCard
  _Misc?: ReactivationMisc
  misc?: ReactivationMisc | null
  ssn?: string
  firstName: string
  income?: ReactivationIncome | null
  localId?: string
  lastName: string
  customerId: number
  birthDate: string
  documentResponses?: {
    borrowerAffidavitConfirmed: boolean
  } | null
  addresses: ReactivationAddress[]
  phoneNumbers: ReactivationPhoneNumber[]
  isMilitary?: boolean
  middleName?: string | null
  suffix?: string | null
  heardAboutId?: number
  bank: ReactivationBank
  identification?: ReactivationIdentification
  applicationConsentDocs?: ReactivationApplicationConsentDoc[]
  supplementalInformation?: ReactivationSupplementalInformation | null
  loanRequestInformation?: ReactivationLoanRequestInformation | null
  personalProperty?: ReactivationPersonalProperty
  employment: ReactivationEmployment
  preQualification?: null
  preferredLangId?: null
  properties?: ReactivationProperties[]
}

/**
 * Interface for reactivation properties
 */
export interface ReactivationProperties {
  propertyType: number
  vehicleType: string
  ownerName: string
  coOwnerName: string
  vin: string
  year: 0
  make: string
  model: string
  series: string
  bodyStyle: string
  engineCylinders: string
  doors: string
  purchased: string
  purchasedDate: string
  fuelType: string
  color: string
  secondaryColor: string
  mileage: number
  isMileageExempt: true
  condition: string
  estimatedValue: 0
  titleNumber: string
  titleStateId: 0
  tagNumber: string
  tagStateId: 0
  tagCounty: string
  tagExpireDate: string
  insuranceCoverage: 0
  insuranceCompany: string
  insurancePolicyNum: string
  insuranceEffectiveDate: string
  insuranceExpirationDate: string
  insuranceCoverageAmount: 0
  insurancePhoneNumber: string
  cvrControlNumber: string
  cvrRequestNumber: string
  cvrStateAppNumber: string
  cvrUpdated: string
  maxLoanAmount: 0
  description: string
  lienStatus: 0
  propertyStatus: 0
  haveKey: true
  hasWriting: true
  lienPlaced: true
  serialNumber: string
}

/**
 * Reactivation requested body for create endpoint
 */
export interface ReactivationCreateBody {
  disbursementType?: number
  properties?: ReactivationProperties[]
  primaryApplicant: ReactivationApplicantBody
  coBorrowers?: ReactivationApplicantBody[]
  applicationId?: string
}

/**
 * CustomerDetails fetched by /loans/find-by-id endpoint on Edge Service
 * TODO: Remove comments
 */
export interface CustomerDetails {
  customerMobileProfileId: number
  customerAddress: {
    addressId: number
    addressLine1: string
    addressLine2: string
    city: string
    moveInDate: string | null
    postalCode: string
    state: string
  }
  customerBankAccounts: {
    id: number
    accountNumber: string
    bankName: string
    isBankAccountActive: boolean
    isCheckingAccount: boolean
  }[]
  customerCards: CustomerCard[]
  customerEmployers: {
    id: number
    employerName: string
    frequencyOfPay: string
    grossPay: string
    paycheckAmount: number
  }[]
  customerPhoneNumber: {
    phoneId: number
    phoneNumber: string
    phoneNumberType: string
  }
  personReferenceNumber: string
  personId?: string
  portalDisabled: boolean
  reactivationRefiDisabled: boolean
  birthDate: string
  companyId: number
  emailAddress: string
  firstName: string
  lastName: string
  customerStatus: string
  reactivationOnlineStoreId: number | null | undefined
  refinanceModelOption: RefinanceModelOption[]
}

/**
 * CustomerContactPreferences fetched by /loans/find-by-id endpoint on Edge Service
 */
export interface CustomerContactPreferences {
  personReferenceNumber: string
  dataStateCd: number
  isMarketingEmail: boolean
  isMarketingMail: boolean
}

/**
 * Interface for form values that will be sent to edge service for retrieving loan info
 */

/**
 * Types of loan payments
 */
export enum PaymentMethodTypes {
  NULL = '',
  EXISTING_CARD = 'existing-card',
  ONE_TIME_CARD = 'one-time-card',
  NEW_CARD = 'new-card',
  ADD_CARD_AND_PAY = 'add-card-and-pay',
}

/**
 * Interface for form values for Reactivation page
 */
export interface ReactivationFormValues {
  loanAmount: string
  offerId: number
  disbursementMethod: PaymentMethodTypes
}

/**
 * Interface for form values for Cash Back Refinance page
 */
export interface CashbackFormValues extends RefinanceFormValues {
  cashbackAmount: string
}

/**
 * Interface for form values for Refinance page
 */
export interface RefinanceFormValues extends LoanPaymentFormValues {
  firstPaymentDate: string
  disbursementMethod: PaymentMethodTypes
  autoPaymentMethod: PaymentMethodTypes
  autoCvv: string
}

/**
 * Interface for form values for Title Refinance page
 */
export interface RefinanceTitleFormValues extends LoanPaymentFormValues {
  firstPaymentDate: string
  disbursementMethod: PaymentMethodTypes
  autoPaymentMethod: PaymentMethodTypes
  autoCvv: string
}

/**
 * Interface for form values that will be gathered in make payment form
 */
export interface LoanPaymentFormValues {
  paymentAmount: string
  paymentMethod: PaymentMethodTypes
  cvv: string
  disbursementMethod?: PaymentMethodTypes
}

/**
 * Interface for the payload body of request sent to schedule a payment
 */
export interface PostPaymentValues {
  cardCvv?: string
  loanId: number
  amountDue: number
  cardId: number
  oAuthToken: string
  // AuthCode?: string
  isInRescindPeriod: boolean
  customerId: number
}

/**
 * Interface for the payload body of request sent to schedule a payment
 */
export interface DeletePaymentMehodsValues {
  customerId: number
  paymentMethodId: number
}

/**
 * Interface for offer response
 */
export interface OfferDataResponse {
  logId: number
  decisionType: string
  isApproved: boolean
  offers: [
    {
      loanModelId: number
      decisionProductCode: string
      amount: number
      isCounterOffer: boolean
      loanClassType: number
      isEnrollAutoPayments: boolean
      displayName: string
    },
  ]
}

/**
 * Interface for amounts response
 */
export interface AmountsResponse {
  loanModelId: number
  amounts: number[]
  minMaxRanges: {
    minimum: number
    maximum: number
  }[]
}

/**
 * Interface for amounts funding methods response
 */
export interface ScheduleResponse {
  loanModelId: number
  amount: number
  feesAmount: number
  apr: number
  numberOfPayments: number
  paymentAmount: number
  payFrequencyWording: string
  firstDueDate: string
  secondDueDate: string
  drawFeeFlat: number
  drawFeePercent: number
  isPrePaidPrincipalSubtract: boolean
  loanClassType: number
  minimumDrawAmount: number
  paymentSchedule: [
    {
      pmtId: number
      pmtNumber: number
      startDate: string
      dueDate: string
      days: number
      balance: number
      payment: number
      prin: number
      fee: number
      payoff: number
      paidDate: string
      errors: string[]
    },
  ]
  preventAutoPay: boolean
}

/**
 *Interface that matches detail on payment success
 */
export interface PaymentSuccessDetail {
  success: boolean
  transactionId: number
}

/**
 * Interface for response body after POST request for scheduling a payment
 */
export interface PostPaymentResponse {
  success: boolean
  detail: string
  paymentReceipt: TransactionModel | undefined
  paymentId: number
}

/**
 * Interface for response on reactivation create
 */
export interface PostReactivationCreateResponse {
  responseToken: string
}

/**
 * Interface for response on Reactivation Sync
 */
export interface ReactivationSyncResponse {
  customerId: number
}

/**
 * Interface for response on post reactivation endpoint vergent application
 */
export interface ReactivationPostApplicationDetails {
  success: boolean
}

/**
 *Interface for response on post
 */
export interface ReactivationInitialConfirmationResponse {
  isApproved: boolean
  portalTokenUrl: string
}

/**
 * Interface that matches PaymentReceiptAllocationDto
 */
export interface PaymentReceiptAllocation {
  principalAmount: number
  amountReceived: number
  balanceDue: number
  collectionFees: number
  financeCharges: number
  total: number
}

/**
 * Interface that matches RefiPaymentReceipt
 */
export interface RefiPaymentReceipt {
  currentBalance: number
  paymentAmount: number
  transactions: {label: string; amount: number}[]
}

/**
 * Interface for payload body for requesting a potential loan schedule
 */
export interface GetLoanScheduleValues {
  loanAmount: number
  numberOfPeriods: number
  loanId: number
  firstPaymentDate?: string
  amountRequested: number
  feeBalance: number
  currentBalance: number
  apr: number
  isReactivation?: boolean
  offerId?: number
}

/**
 * Interface for card details for refinance payments
 */
export interface PaymentDetails {
  cardCvv?: string
  paymentAmount: number
  token: number
  debitCardLast4: string
}

/**
 * Interface mapped from ServiceReference.DebitCardProcessedPaymentResponseDto
 */
export interface PaymentDetailsProcessed {
  authorizationNumber: string
  debitCardLast4: string
  isDebitCardProcessedSuccessfully: boolean
}

/**
 * Interface mapped from ServiceReference.ConfirmRefinanceAndBuildDocumentsRequest
 */
export interface ConfirmRefinanceLoanValues {
  customerId: number
  companyId: number
  storeId: number
  loanModelId: number
  cosignerId?: number
  originationDate: string //Today's date or stores current date
  fundingDate: string //Today's date or stores current date
  loanAmount: number //Remaining loan balance to be carrired over
  dueDate: string | null // First dueDate from the payment scehdule returned in GetRefinanceEstimate
  numberOfPayments: number // # of payments from the payment scehdulereturned in GetRefinanceEstimate
  bankId: number // From loan/full response
  fundingMethod: string
  fundingLocation?: string
  fundingCardId?: number
  workflowId?: number
  autopayEnrollment: {
    enrollAutopay: boolean
    autopayType?: string
    autopayCardId?: number
    autopayBankId?: number
  }
  refinanceData?: [
    {
      headerId: number //Current loanId
      loanModelId: number
      storeId: number
      payment: {
        amount: number
        paymentDate: string //Should besame date as originationDate above
        paymentMethod: string
        bankId: number
        cardId: number //From loan/full response
      }
    },
  ]
  initialLoanStatus?: string
  initialDrawAmount?: number
}

/**
 * Interface mapped from ServiceReference.ConfirmRefinanceAndBuildDocumentsRequest
 */
export interface ConfirmRefinanceCashbackLoanValues {
  simplifiedLoanRequest: ConfirmRefinanceLoanValues
}

/**
 * Interface mapped from ServiceReference.ConfirmLoanAndBuildDocumentsRequest
 */
export interface ConfirmOriginateLoanValues {
  payoutDetails: PaymentDetails
  selectedFirstPaymentDate: string
  selectedProductId: number
  previousLoanId: number
}

/**
 * Interface mapped from ServiceReference.RefinanceLoanRequest
 */
export interface RefinanceLoanValues {
  personReferenceNumber: string
  personId: string
  previousLoanId: number
  newLoanId: number
  refiCashOutPaymentDetails: PaymentDetails | null
  refiPaymentDetails: PaymentDetails
  customerId: number
  loanHeaderId: number
}

/**
 * Interface mapped from ServiceReference.OriginateLoanRequest
 */
export interface OriginateLoanValues {
  loanApplicationId: number
  payOutPaymentDetails: PaymentDetails
  personReferenceNumber: string
  personId: string
  previousLoanId: number
}

/**
 * Interface mapped from ServiceReference.ConfirmRefinanceAndBuildDocumentsResponse and ServiceReference.ConfirmLoanAndBuildDocumentsResponse
 */
export interface ConfirmRefinanceLoanResponse {
  paymentResult: {
    newLoanId: number
  }
  documentUrl: string
}

/**
 * Intercafe mapped from ServiceReference.DocumentResponse
 */
export interface DocumentResponse {
  deferESign: boolean
  eSignUrl: string
  loanId: number
  pendingESignGuid: string
}

/**
 * Interface mapped from ServiceReference.LoanDocuments
 */
export interface DownloadDocuments {
  documentName: string
  documentUrl: string
}

/**
 * List of status to be used on GetSigningStatus EP
 */
export enum SigningStatus {
  Completed = 'Completed',
}

/**
 * Interface mapped from GetSigningStatus
 */
export interface GetSigningStatus {
  detail: string
  errorMessage: string
  errorType: string
  signingStatus: string
}

/**
 * Interface mapped from ServiceReference.RefinanceLoanResponse
 */
export interface RefinanceLoanResponse {
  loanId: number
  // LoanOriginationReceiptDto: PaymentReceiptAllocation | null
  refiPayOutProcessedDebitcardResponseDto: PaymentDetailsProcessed | null
  refiPaymentProcessedDebitcardResponseDto: PaymentDetailsProcessed | null
  signedLoanAgreementDocumentUrl: DownloadDocuments[]
  refiPaymentReceiptDto: RefiPaymentReceipt | null
}

/**
 * Interface mapped from ServiceReference.OriginateLoanResponse
 */
export interface OriginateLoanResponse {
  loanId: number
  payOutPaymentProcessedDebitcardResponseDto: PaymentDetailsProcessed
  message: string
  responseCode: number
  signedLoanAgreementDocumentUrl: DownloadDocuments[]
}

/**
 * Interface mapped from ServiceReference.TilaDisclosureDetails
 */
export interface TilaDisclosureDetailsDto {
  amountFinanced: number
  apr: number
  financeCharge: number
  scheduledTotalPaymentAmount: number
  firstPaymentDate?: string
}

/**
 * Interface mapped for LoanScheduleResponse
 */
export interface LoanScheduleResponse {
  loanSchedule: ScheduleItem[]
  tilaDisclosureDetailsDto: TilaDisclosureDetailsDto
}

/**
 * Interface mapped for HealthResponse
 */
export interface HealthCheckResponse {
  name: string
  status: string
  isHealthy: boolean
  info: {
    status: string
    error: string
  }[]
}

/**
 * Enum of possible productId's
 */
export enum ProductId {
  FOURTEEN_DAY_LOAN_TX = 33,
  THIRTYONE_DAY_LOAN_TX = 34,
  FOURTEEN_DAY_LOAN_NM = 13,
  THIRTYONE_DAY_LOAN_NM = 14,
  FOURTEEN_DAY_LOAN_ID = 18,
  THIRTYONE_DAY_LOAN_ID = 20,
}

/**
 * Response for /customer/cashwise-info endpoint on Edge Service
 */
export interface CashWiseInformationRequest {
  phone: string
  code: string
}

/**
 * Response for /customer/cashwise-info endpoint on Edge Service
 */
export interface CashWiseInformationResponse {
  [key: string]: number | string | undefined
  firstName: string
  lastName: string
  storePhone: string
  storeTimezone: string
  mondayOpen: number
  mondayClose: number
  tuesdayOpen: number
  tuesdayClose: number
  wednesdayOpen: number
  wednesdayClose: number
  thursdayOpen: number
  thursdayClose: number
  fridayOpen: number
  fridayClose: number
  saturdayOpen: number
  saturdayClose: number
  sundayOpen: number
  sundayClose: number
}

/**
 * Enum of possible status
 */
export enum StatusAction {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  DELETE = 'DELETE',
}

/**
 *Response for PaymentSchedule to /VergentLoans/full
 */
export interface PaymentSchedule {
  pmtId: number
  pmtNumber: number
  startDate: Date
  dueDate: Date
  locDueDate: Date
  days: number
  balance: number
  payment: number
  prin: number
  fee: number
  payoff: number
  paidDate: string
  errors: string[]
}

/**
 *Body values for /VergentLoans/RequestPinByText
 */
export interface RequestPinByTextBody {
  phoneNumber: string
  type: number
  customerId: number
}

/**
 *Request for PaymentSchedule to /VergentCommunications/VerifyPin
 */
export interface VerifyPinRequest {
  phoneNumber: string
  pin: string
  type: number
  customerId: number
}

/**
 *Response for RequestPinByText to /VergentLoans/RequestPinByText
 */
export interface RequestPinByTextResponse {
  status: 0
  result: boolean
  message: string
}

/**
 *Response for RequestPinByText to /VergentCommunications/VerifyPin
 */
export interface VerifyPinResponse {
  status: 0
  result: boolean
  message: string
}

/**
 * GetReactivationPaymentScheduleResponse
 */
export interface GetReactivationPaymentScheduleResponse {
  paymentSchedule?: PaymentSchedule[]
  loanModelId: number
  amount: number
  feesAmount: number
  apr: number
  numberOfPayments: number
  paymentAmount: number
  payFrequencyWording?: string
  firstDueDate: string
  secondDueDate: string
  drawFeeFlat: number
  drawFeePercent: number
  isPrePaidPrincipalSubtract: boolean
  loanClassType: number
  minimumDrawAmount: number
  preventAutoPay: boolean
}

/**
 * GetReactivationPaymentScheduleArgs
 */
export interface GetReactivationPaymentScheduleArgs {
  applicationId: string
  loanModelId: number
  amount: number
  customerId: number
}

/**
 * ReactivationSubmitResponse
 */
export interface ReactivationSubmitResponse {
  applicationId: string
  workflowId: number
  loanHeaderId: number
  customerId: number
  eSignUrl: string
  portalTokenUrl: string
}

/**
 * ReactivationSubmitPayload
 */
export interface ReactivationSubmitPayload {
  customerId: number
  loanModelId: number
  loanAmount: number
  isAutoPay: boolean
  drawAmount: number
  disbursementType: number
  disbursementId: number
  paymentMethodId: number
  paymentType: number
  fundingMethod: number
}

/**
 * Interface for download documents in myAccount
 */
export interface MyAccountDownloadDocuments {
  documentName: string
  documentUrl: string
  loanId: string
  originationDate: string
}

/**
 * DecisionFullPayload
 */
export interface DecisionFullPayload {
  applicationId?: string
  oAuthToken: string
  customerId: number
}
