import React, {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {Formik, Form, FormikProps} from 'formik'
import FormikErrorFocus from 'formik-error-focus'
import {
  Card,
  Col,
  Container,
  Form as BootstrapForm,
  Row,
} from 'react-bootstrap'
import {MdErrorOutline} from 'react-icons/md'

import MaskedInput from 'react-text-mask'
import * as Yup from 'yup'

import Content from 'components/Content'
import SpinnerButton from 'components/SpinnerButton'
import SSNInput from 'components/Form/SSNInput'
import {ContentSlot, TextContent} from 'models/Content'
import {phoneInputMask, dateInputMask} from 'utils/forms/masks'
import {AnalyticsPageNames, AnalyticsEventNames} from 'models/Analytics'
import FindMyAccountPopup from 'components/Popup/FindMyAccountPopup'
import {getUtmParametersFromLocalStorage, trackUtmEvent} from 'utils/analytics'
import {SearchAccountFormValues} from 'models/AccountFormValues'
import {useContent} from 'utils/hooks/useContent'
import {useAuth} from 'auth'
import {getConfigValue} from 'utils/environment'
import type {StandardError} from 'models/Errors'
import {SearchCustomerErrorTypes} from 'auth/actions/search-customer-account-action'
import {logUnknownAsException} from 'AppInsights'
import {ToastContext} from 'components/Toast'
import {
  getSearchCustomerAttemptsCookie,
  CUSTOMER_SEARCH_ATTEMPTS_LIMIT,
} from 'auth/handle-lock-search-customer'
import {Routes as Path} from 'models/Routes'

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  idNumber: Yup.string()
    .required('Social security number is required')
    .matches(
      /**
       * Due to some very specific SSN rules, this regex is not just simple groups of digits
       * See link for more info: https://regex101.com/r/rA2xA2/1
       */
      /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/,
      'A valid social security number is required',
    ),
  birthDate: Yup.string()
    .required('Date of birth is required')
    .matches(
      /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
      'Date of birth must match this format: MM/DD/YYYY',
    ),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
      'A valid phone number is required',
    ),
})

const cashwiseFeatureFlag: boolean =
  getConfigValue('CASHWISE_ENABLED') === 'true'

const disableFindLoanPage = getConfigValue('DISABLE_FIND_LOAN_PAGE')

const FindLoan: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.FIND_LOAN,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * State, Hooks
   */
  const [accountSearchAttempts, setAccountSearchAttempts] = useState(
    getSearchCustomerAttemptsCookie(),
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {pushToast} = useContext(ToastContext)
  const {searchCustomerAccount} = useAuth()
  const history = useHistory()
  const findLoanError = useContent<TextContent>(
    ContentSlot.FINDLOAN_FINDLOANERROR,
  )

  if (disableFindLoanPage && disableFindLoanPage.toLowerCase() === 'true') {
    history.push(Path.LOGIN)
  }

  useEffect(() => {
    if (accountSearchAttempts >= CUSTOMER_SEARCH_ATTEMPTS_LIMIT) {
      history.push('/no-loan-found')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  const [findMyAccountVisible, setFindMyAccountVisible] = useState(false)
  const utmParameters = getUtmParametersFromLocalStorage()

  useEffect(() => {
    // We call this here in an effect, in case a user was referred with a UTM parameter
    if (Object.keys(utmParameters).length > 0) {
      trackUtmEvent(appInsightsContext, utmParameters)
    }
  }, [appInsightsContext, utmParameters])

  const initialFormValues: SearchAccountFormValues = {
    firstName: '',
    lastName: '',
    idNumber: '',
    birthDate: '',
    phoneNumber: '',
  }

  /**
   * Submit form values
   * @param values form values
   */
  async function submitFormValues(values: SearchAccountFormValues) {
    setIsSubmitting(true)

    const formattedValues: SearchAccountFormValues = {
      ...values,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
    }

    const formValuesWithoutSsn = {
      firstName: formattedValues.firstName,
      lastName: formattedValues.lastName,
      ssn: `***-**-${formattedValues.idNumber.substr(
        formattedValues.idNumber.length - 4,
        4,
      )}`,
      phone: formattedValues.phoneNumber,
      dateOfBirth: formattedValues.birthDate,
    }

    appInsightsContext.trackEvent(
      {name: AnalyticsEventNames.FIND_LOAN},
      formValuesWithoutSsn,
    )

    try {
      await searchCustomerAccount(formattedValues)

      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.FIND_LOAN_SUCCESS},
        formValuesWithoutSsn,
      )

      history.push('/register')
      return
    } catch (error) {
      const {type, errorInstance, auxiliarNumberProp} = error as StandardError

      appInsightsContext.trackEvent(
        {name: AnalyticsEventNames.FIND_LOAN_FAILURE},
        formValuesWithoutSsn,
      )

      if (type === SearchCustomerErrorTypes.LockedAccountSearch) {
        if (errorInstance) {
          logUnknownAsException(errorInstance)
        } else {
          logUnknownAsException(error)
        }
        history.push('/no-loan-found')
        return
      } else if (Number(auxiliarNumberProp) !== accountSearchAttempts) {
        setAccountSearchAttempts(Number(auxiliarNumberProp))
      }

      if (
        cashwiseFeatureFlag &&
        type === SearchCustomerErrorTypes.NoLoanFound &&
        Number(auxiliarNumberProp) > 1
      ) {
        setFindMyAccountVisible(true)
      }

      if (
        type === SearchCustomerErrorTypes.InternalServerError ||
        type === SearchCustomerErrorTypes.NoLoanFound
      ) {
        pushToast({
          message: findLoanError?.value.value,
          title: 'Unable to find loan',
          variant: 'danger',
        })
        if (errorInstance) {
          logUnknownAsException(errorInstance)
        } else {
          logUnknownAsException(error)
        }
      }

      if (type === SearchCustomerErrorTypes.UnknownErrorOccured) {
        pushToast({
          title: 'Unable to Find Loan',
          message:
            'Error occured while trying to find loan. Please try again, and reach out to us if error persists.\n\nErrorCode: FL004',
          variant: 'danger',
        })

        if (errorInstance) {
          logUnknownAsException(errorInstance)
        } else {
          logUnknownAsException(error)
        }
      }
    }

    setIsSubmitting(false)
  }

  return (
    <Container>
      <Card className="my-3">
        <Card.Header className="text-center">
          <h1>
            <Content type={ContentSlot.FINDLOAN_HEADER} />
          </h1>
          <div>
            <Content type={ContentSlot.FINDLOAN_FORM_DESCRIPTION} />
          </div>
        </Card.Header>

        <Formik
          validateOnBlur
          initialValues={initialFormValues}
          validateOnChange={false}
          validationSchema={FormSchema}
          onSubmit={submitFormValues}
        >
          {(props: FormikProps<SearchAccountFormValues>) => (
            <Form>
              <Card.Body>
                <Container>
                  <Row>
                    <Col lg="6" xs="12">
                      <BootstrapForm.Group controlId="firstName">
                        <BootstrapForm.Label>First Name</BootstrapForm.Label>
                        <BootstrapForm.Control
                          data-ref="input-firstName"
                          name="firstName"
                          title="First Name"
                          type="text"
                          value={props.values.firstName}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                        />
                        {props.touched.firstName && props.errors.firstName && (
                          <BootstrapForm.Text className="text-danger">
                            {props.errors.firstName}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                    <Col lg="6" xs="12">
                      <BootstrapForm.Group controlId="lastName">
                        <BootstrapForm.Label>Last Name</BootstrapForm.Label>
                        <BootstrapForm.Control
                          data-ref="input-lastName"
                          name="lastName"
                          title="Last Name"
                          type="text"
                          value={props.values.lastName}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                        />
                        {props.touched.lastName && props.errors.lastName && (
                          <BootstrapForm.Text className="text-danger">
                            {props.errors.lastName}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" xs="12">
                      <BootstrapForm.Group controlId="idNumber">
                        <BootstrapForm.Label>SSN</BootstrapForm.Label>
                        <div className="input-group">
                          <SSNInput
                            value={props.values.idNumber}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                          />
                        </div>
                        {props.touched.idNumber && props.errors.idNumber && (
                          <BootstrapForm.Text className="text-danger">
                            {props.errors.idNumber}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                    <Col lg="6" xs="12">
                      <BootstrapForm.Group controlId="birthDate">
                        <BootstrapForm.Label>
                          Date of Birth
                        </BootstrapForm.Label>
                        <MaskedInput
                          className="form-control"
                          data-ref="input-dob"
                          mask={dateInputMask}
                          name="birthDate"
                          title="Date of Birth"
                          type="tel"
                          value={props.values.birthDate}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                        />
                        {props.touched.birthDate && props.errors.birthDate && (
                          <BootstrapForm.Text className="text-danger">
                            {props.errors.birthDate}
                          </BootstrapForm.Text>
                        )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" xs="12">
                      <BootstrapForm.Group controlId="phoneNumber">
                        <BootstrapForm.Label>Phone Number</BootstrapForm.Label>
                        <MaskedInput
                          className="form-control"
                          data-ref="input-phone"
                          mask={phoneInputMask}
                          name="phoneNumber"
                          title="Phone Number"
                          type="tel"
                          value={props.values.phoneNumber}
                          onBlur={props.handleBlur}
                          onChange={props.handleChange}
                        />
                        {props.touched.phoneNumber &&
                          props.errors.phoneNumber && (
                            <BootstrapForm.Text className="text-danger">
                              {props.errors.phoneNumber}
                            </BootstrapForm.Text>
                          )}
                      </BootstrapForm.Group>
                    </Col>
                  </Row>
                  {accountSearchAttempts === 3 && (
                    <Row
                      className="justify-content-center py-3"
                      data-ref="find-my-account-warning"
                    >
                      <Col
                        className="justify-content-center d-flex"
                        lg="1"
                        md="3"
                        sm="3"
                      >
                        <MdErrorOutline color="#efd36d" size="3.5rem" />
                      </Col>
                      <Col className="text-center" md="7" sm="7">
                        <Content type={ContentSlot.FINDLOAN_3ATTEMPTS} />
                      </Col>
                    </Row>
                  )}
                  <Row className="justify-content-center">
                    <Col lg="auto" xs="12">
                      <SpinnerButton
                        block
                        color="primary"
                        data-ref="submit"
                        loading={isSubmitting}
                        size="lg"
                        type="submit"
                      >
                        <Content type={ContentSlot.FINDLOAN_BUTTON_TEXT} />
                      </SpinnerButton>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <FormikErrorFocus
                align="middle"
                duration={500}
                ease="linear"
                focusDelay={200}
              />
            </Form>
          )}
        </Formik>
      </Card>
      <FindMyAccountPopup
        close={setFindMyAccountVisible}
        show={findMyAccountVisible}
      />
    </Container>
  )
}

/**
 * Find Loan page
 */
export default FindLoan
