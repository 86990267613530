import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js'
import {useMutation} from 'react-query'
import {Button, Card, Col, Container, Row} from 'react-bootstrap'
import {MdChevronLeft} from 'react-icons/md'
import {useHistory, Redirect} from 'react-router-dom'

import {logUnknownAsException} from 'AppInsights'
import {queryClient} from 'client'
import Content from 'components/Content'
import {ContentSlot} from 'models/Content'
import {AdobeMessageEvent} from 'models/AdobeMessage'
import NumberStepper from 'components/NumberStepper'
import {
  RefinanceSteps,
  ReactivationSteps,
} from 'components/NumberStepper/Config'
import FolderSpinner from 'components/FolderSpinner'
import {ToastContext} from 'components/Toast'
import {
  RefinanceLoanValues,
  RefinanceLoanResponse,
  OriginateLoanResponse,
  ConfirmRefinanceLoanValues,
  ConfirmRefinanceCashbackLoanValues,
  SigningStatus,
} from 'models/Edge'
import {
  getRefinanceData,
  getReactivationData,
  setSuccessfulResponse,
} from 'utils/cache'
import {
  startRefinanceApplication,
  completeRefinanceApplicationWithDelay,
  startReactivationApplication,
  completeReactivationApplicationWithDelay,
  logErrorToApplicationInsights,
  startRefinanceCashBackApplication,
  StartReactivationApplicationArgs,
  CompleteReactivationApplicationWithDelayArgs,
  getSigningStatus,
} from 'utils/edge'
import {getConfigValue} from 'utils/environment'
import AdobeSignError from './AdobeSignError'
import {
  AnalyticsEventNames,
  AnalyticsExceptionNames,
  AnalyticsPageNames,
} from 'models/Analytics'
import {getSignDocumentsPageViewEventName} from 'utils/analytics'
import {
  docErrorHeader,
  docErrorRefiMessage,
  documentAlreadySignedError,
  documentNotSignedError,
} from 'utils/constants'
import {VergentError} from 'models/Errors'
import {Routes} from 'models/Routes'
import {useAuth} from 'auth'
import {useLoanData} from 'utils/hooks/useLoanData'
import {getFormatedFullDate} from 'utils/dates'
import {useRefinanceData} from 'utils/hooks/useRefinanceData'

/**
 * Checks for document related errors
 * @param message error message
 * @return check for document error
 */
export function checkForDocumentErrorInResponseMessage(
  message: string | undefined,
): boolean {
  return (
    message === documentAlreadySignedError ||
    message === documentNotSignedError
  )
}

const ConfirmRefinance: React.FC = () => {
  /**
   * Analytics & Tracking
   */
  const appInsightsContext = useAppInsightsContext()
  const trackMetric = useTrackMetric(
    appInsightsContext,
    AnalyticsPageNames.SIGN_DOCUMENTS,
  )
  useEffect(() => {
    trackMetric()
  }, [trackMetric])

  /**
   * State, Hooks
   */
  const [adobeError, setAdobeError] = useState(false)
  const [isSignedDocumentOpen, setIsSignedDocumentOpen] = useState(false)
  const history = useHistory()
  const refi = useMemo(getRefinanceData, [])
  const reactivation = useMemo(getReactivationData, [])
  const adobeDocumentSignOrigin = getConfigValue('ADOBE_SIGN_URL')
  const {pushToast} = useContext(ToastContext)
  const {user} = useAuth()
  const {currentLoan, customer, data: loanData} = useLoanData()
  const {refiOptions} = useRefinanceData()
  const [
    isCheckingForSignedDocuments,
    setIsCheckingForSignedDocuments,
  ] = useState(false)
  const [isFinalizingDocuments, setIsFinalizingDocuments] = useState(false)
  const adminPhoneNumber = getConfigValue('DEFAULT_PHONE_NUMBER')

  let newLoanId = 0
  /**
   * Mutations, Callbacks, Effects
   */

  const onRefinanceError = (error: VergentError) => {
    if (
      error.status !== 200 ||
      checkForDocumentErrorInResponseMessage(error.errorInstance?.message) ||
      !(
        (error as object | undefined)?.hasOwnProperty('silent') ||
        (error as object | undefined)?.constructor?.name === 'CancelledError'
      )
    ) {
      pushToast({
        title: docErrorHeader,
        message: docErrorRefiMessage,
        variant: 'danger',
      })
      failRefinanceRequest()
    }
    logUnknownAsException(error.errorInstance)
  }

  const onReactivationError = (error: VergentError) => {
    if (
      !(
        (error as object | undefined)?.hasOwnProperty('silent') ||
        (error as object | undefined)?.constructor?.name === 'CancelledError'
      )
    ) {
      logUnknownAsException(error)
      failReactivationRequest()
    }
  }

  // Refinance
  const {
    mutateAsync: startRefinanceMutation,
    isLoading: isRefinanceApplicationLoading,
    data: refinanceApplicationData,
  } = useMutation(startRefinanceApplication, {
    onError: onRefinanceError,
    retry: 1,
  })
  const {
    mutateAsync: startRefinanceCashbackMutation,
    isLoading: isRefinanceCashbackApplicationLoading,
    data: refinanceCashbackApplicationData,
  } = useMutation(startRefinanceCashBackApplication, {
    onError: onRefinanceError,
    retry: 1,
  })

  const {
    mutateAsync: completeRefinanceMutation,
    isLoading: isRefinanceSubmissionLoading,
    status: refinanceSubmissionStatus,
  } = useMutation(completeRefinanceApplicationWithDelay, {
    onSuccess: data => {
      if (!data) {
        return declineApplicationRequest()
      }
      navigateToSuccess(data, 'refinanceLoanResponse')
    },
    onError: (error: unknown) => {
      if (
        !(
          (error as object | undefined)?.hasOwnProperty('silent') ||
          (error as object | undefined)?.constructor?.name === 'CancelledError'
        )
      ) {
        logUnknownAsException(error)
        failRefinanceRequest()
      }
    },
  })

  // Reactivation
  const {
    mutateAsync: startReactivationMutation,
    isLoading: isReactivationApplicationLoading,
    data: reactivationApplicationData,
  } = useMutation(startReactivationApplication, {
    onError: onReactivationError,
    retry: 1,
  })

  const {
    mutateAsync: completeReactivationMutation,
    isLoading: isReactivateSubmissionLoading,
    status: reactivateSubmissionStatus,
  } = useMutation(completeReactivationApplicationWithDelay, {
    onSuccess: data => navigateToSuccess(data, 'reactivationLoanResponse'),
    onError: onReactivationError,
  })

  const openSignDocuments = useCallback(openSignDocumentsCallback, [
    reactivationApplicationData,
    refinanceApplicationData,
    refinanceCashbackApplicationData,
  ])

  const goBack = useCallback(goBackCallback, [history])
  const checkSignedDocuments = useCallback(checkSignedDocumentsCallback, [])
  /**
   * Effects
   */
  useEffect(loadApplicationId, [
    refi,
    startReactivationMutation,
    startRefinanceMutation,
    startRefinanceCashbackMutation,
  ])
  useEffect(handleIframeMessage, [
    adobeDocumentSignOrigin,
    checkSignedDocuments,
    logErrorToApplicationInsights,
  ])
  useEffect(openSignDocumentsCallback, [
    reactivationApplicationData,
    refinanceApplicationData,
    refinanceCashbackApplicationData,
  ])
  useEffect(() => {
    let pageViewName = ''
    if (reactivation) {
      pageViewName = getSignDocumentsPageViewEventName('reactivation')
    } else if (refi?.option === 'cashback') {
      pageViewName = getSignDocumentsPageViewEventName('cashback')
    } else {
      pageViewName = getSignDocumentsPageViewEventName('refinance')
    }

    if (pageViewName !== '') {
      appInsightsContext.trackEvent({
        name: pageViewName,
      })
    }
  }, [appInsightsContext, reactivation, refi])

  useEffect(() => {
    document.addEventListener('visibilitychange', checkSignedDocuments)

    return () =>
      document.removeEventListener('visibilitychange', checkSignedDocuments)
  }, [checkSignedDocuments])

  // Redirect to home if user is not in either reactivation or refinance flow
  if (!refi && !reactivation) {
    return <Redirect to="/home" />
  }

  const isReactivateLoading =
    isReactivationApplicationLoading && !reactivationApplicationData
  const isRefinanceLoading =
    isRefinanceApplicationLoading && !refinanceApplicationData
  const isRefinanceCashbackLoading =
    isRefinanceCashbackApplicationLoading && !refinanceCashbackApplicationData

  const applicationIsLoading =
    isReactivateLoading || isRefinanceLoading || isRefinanceCashbackLoading

  const isReactivateSignable = Boolean(
    reactivationApplicationData && reactivateSubmissionStatus !== 'success',
  )
  const isRefinanceSignable =
    refinanceSubmissionStatus !== 'success' &&
    (Boolean(refinanceApplicationData) ||
      Boolean(refinanceCashbackApplicationData))
  const isApplicationSignable = isRefinanceSignable || isReactivateSignable

  const nothingSubmitting =
    !isRefinanceSubmissionLoading &&
    !isReactivateSubmissionLoading &&
    !isCheckingForSignedDocuments
  return (
    <Container>
      <NumberStepper
        activeStep={3}
        steps={refi ? RefinanceSteps : ReactivationSteps}
      />
      {applicationIsLoading ? (
        <FolderSpinner loadingText="Please wait while we get your documents ready for you!" />
      ) : (
        <Card className="full-page mb-3 text-center align-items-center">
          {isApplicationSignable && (
            <>
              <Card.Body>
                {adobeError ? (
                  <AdobeSignError onClick={goHomeCallback} />
                ) : (
                  nothingSubmitting && (
                    <>
                      <div className="text-center m-3">
                        <h1>Sign Documents</h1>
                      </div>
                      <Container fluid className="container-fluid p-2">
                        <p className="mb-4">
                          You&apos;re almost done! Please review and sign the
                          loan documents to finish.
                        </p>
                        <Button
                          data-ref="sign-docs-button"
                          onClick={openSignDocuments}
                        >
                          Review and Sign Documents
                        </Button>
                      </Container>
                      {isSignedDocumentOpen && (
                        <div className="my-3">
                          <span>Already signed documents?</span>
                          <Button
                            className="pl-1 pr-0"
                            variant="link"
                            onClick={checkSignedDocuments}
                          >
                            Click here
                          </Button>
                        </div>
                      )}
                    </>
                  )
                )}

                {isCheckingForSignedDocuments && (
                  <FolderSpinner loadingText="Please wait while we check for your signed documents!" />
                )}

                {(isReactivateSubmissionLoading ||
                  isRefinanceSubmissionLoading ||
                  isFinalizingDocuments) && (
                  <FolderSpinner loadingText="Finalizing Documents - Almost done!" />
                )}
              </Card.Body>
              <Card.Footer>
                <Container>
                  <Row className="mt-3 justify-content-md-between align-items-center">
                    <Col className="col-12 col-md-6 order-2 order-md-1 p-3 p-md-0 text-center text-md-left">
                      {nothingSubmitting && (
                        <Button
                          className="pl-0 text-dark p-md-0 pb-md-5 d-md-flex align-items-center"
                          data-ref="back-button"
                          variant="link"
                          onClick={goBack}
                        >
                          <MdChevronLeft width=".75em" />
                          <Content type={ContentSlot.CONFIRMINFO_BACKBUTTON} />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Card.Footer>
            </>
          )}
        </Card>
      )}
    </Container>
  )

  /**
   * Callbacks
   */

  /**
   *  Do any cleanup and send user to /refinance-issue
   */
  function failRefinanceRequest() {
    history.push('/refinance-issue')
  }

  /**
   * Do any cleanup and send user to /reactivate-issue
   */
  function failReactivationRequest() {
    history.push('/reactivate-issue')
  }

  /**
   * Push toast if application request fails to fetch documents
   */
  function declineApplicationRequest() {
    let redirectTo = 'refinance'
    if (reactivation) {
      redirectTo = 'reactivate'
    }
    pushToast({
      title: `Unable to ${redirectTo}`,
      variant: 'danger',
    })
    history.push(`${redirectTo}-issue`)
  }

  /**
   * Takes refinance data from cache and tries to retrieve the application id
   */
  function loadApplicationId() {
    if (refi) {
      startRefiApplication()
    } else if (reactivation) {
      startReactApplication()
    }
  }

  /**
   * Grabs all refinance info in the query cache and makes the request to get an application id
   */
  async function startRefiApplication() {
    if (
      !refi ||
      !refi.refinance.paymentFrequencyId ||
      !customer ||
      !currentLoan ||
      !currentLoan.refinanceApprovalDetails
    ) {
      return
    }
    const auxDate = getFormatedFullDate(new Date())
    const loanAmount = Number(
      (currentLoan.todaysPayoffAmount - refi.refinance.paymentAmount).toFixed(
        2,
      ),
    )
    const application = {
      customerId: Number(user?.personReferenceNumber),
      companyId: refi.refinance.companyId,
      storeId: Number(refi.refinance.storeId),
      loanModelId: refi.refinance.loanModelId,
      originationDate: auxDate,
      fundingDate: auxDate,
      loanAmount,
      dueDate: getFormatedFullDate(new Date(refi.refinance.firstPaymentDate)),
      numberOfPayments: refi.numberOfPeriods,
      bankId: customer.customerBankAccounts[0].id,
      fundingMethod: 'none',
      autopayEnrollment: {
        enrollAutopay: true,
        autopayType: 'CARD',
        autopayCardId: Number(refi.refinance.cardId),
        autopayBankId: customer.customerBankAccounts[0].id,
      },
      refinanceData: [
        {
          headerId: currentLoan.loanId,
          loanModelId: currentLoan.loanModelId,
          storeId: Number(currentLoan.storeId),
          payment: {
            amount:
              currentLoan.refinanceApprovalDetails.refinanceOptions[0]
                .requiredToPay,
            paymentDate: auxDate,
            paymentMethod: 'CARD',
            bankId: customer.customerBankAccounts[0].id,
            cardId: Number(refi.refinance.cardId),
          },
        },
      ],
    } as ConfirmRefinanceLoanValues

    if (refi.option === 'cashback' && refiOptions) {
      const loanCashbackAmount =
        Number(
          (
            currentLoan.todaysPayoffAmount - refi.refinance.paymentAmount
          ).toFixed(2),
        ) + refi.cashback.cashbackAmount
      const applicationCashBack = {
        simplifiedLoanRequest: {
          ...application,
          loanAmount: loanCashbackAmount,
          cosignerId: 0,
          fundingLocation: 'NotSpecified',
          fundingMethod: 'CARD',
          fundingCardId: Number(refi.refinance.disbursementCardId),
          workflowId: 0,
          autopayEnrollment: {
            enrollAutopay: true,
            autopayType: 'CARD',
            autopayCardId: Number(refi.refinance.disbursementCardId),
            autopayBankId: customer.customerBankAccounts[0].id,
          },
          initialLoanStatus: 'None',
          initialDrawAmount: 0,
        },
      } as ConfirmRefinanceCashbackLoanValues
      try {
        const response = await startRefinanceCashbackMutation(
          applicationCashBack,
        )
        const {newLoanId: loanId} = response.paymentResult
        if (loanId === 0) {
          throw new Error()
        }
        newLoanId = loanId
        return
      } catch {
        declineApplicationRequest()
        return
      }
    }
    try {
      const response = await startRefinanceMutation(application)
      const {newLoanId: loanId} = response.paymentResult
      if (loanId === 0) {
        throw new Error()
      }
      newLoanId = loanId
    } catch {
      declineApplicationRequest()
    }
  }

  /**
   * Grabs all reactivation info in the query cache and makes the request to get an application id
   */
  async function startReactApplication() {
    if (!reactivation) return
    const application: StartReactivationApplicationArgs = {
      customerId: Number(user?.personReferenceNumber),
      applicationId: reactivation.applicationId,
      loanModelId: reactivation.loanModelId,
      loanAmount: reactivation.loanAmount,
      isAutoPay: reactivation.isAutoPay,
      drawAmount: reactivation.drawAmount,
      disbursementType: reactivation.disbursementType,
      disbursementId: reactivation.disbursementId,
      paymentType: reactivation.paymentType,
      paymentMethodId: reactivation.disbursementId,
      fundingMethod: reactivation.fundingMethod,
    }

    try {
      const response = await startReactivationMutation(application)
      const {newLoanId: loanId} = response.paymentResult
      newLoanId = loanId
    } catch {
      declineApplicationRequest()
    }
  }

  /**
   * A timeout function used for introducing a delay.
   * @param milliseconds The number of milliseconds to set a timeout/delay for.
   */
  async function timeout(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  /**
   * CheckSignedDocumentsCallback
   */
  async function checkSignedDocumentsCallback() {
    try {
      if (document.visibilityState === 'visible') {
        setIsCheckingForSignedDocuments(true)
        await timeout(2000)
        const signingStatus = await getSigningStatus(newLoanId)
        setIsCheckingForSignedDocuments(false)
        if (signingStatus.signingStatus !== SigningStatus.Completed) {
          pushToast({
            title: 'Documents have not been signed.',
            message: `Please try again. If error persists, call ${adminPhoneNumber}.`,
            variant: 'danger',
          })
          return
        }
        setIsFinalizingDocuments(true)
        if (refi && newLoanId > 0) {
          const application = {
            newLoanId,
            personReferenceNumber: refi.personReferenceNumber,
            personId: refi.personId,
            previousLoanId: refi.loanId,
            refiCashOutPaymentDetails:
              refi.option === 'cashback'
                ? {
                    paymentAmount: refi.cashback.cashbackAmount,
                    token: refi.cashback.cardToken,
                    debitCardLast4: refi.cashback.cardTokenLast4,
                  }
                : null,
            refiPaymentDetails: {
              cardCvv: refi.refinance.cardCvv?.toString() ?? '',
              paymentAmount: refi.refinance.paymentAmount,
              token: refi.refinance.cardToken,
              debitCardLast4: refi.refinance.cardTokenLast4,
            },
            selectedFirstPaymentDate: refi.refinance.firstPaymentDate,
            selectedNumberOfPeriods: refi.numberOfPeriods,
            selectedProductId: refi.productId,
            customerId: Number(loanData?.customerDetails.personId),
            loanHeaderId: currentLoan?.loanId,
          } as RefinanceLoanValues

          await completeRefinanceMutation(application)
          setIsFinalizingDocuments(false)
        } else if (reactivation && newLoanId > 0) {
          const application: CompleteReactivationApplicationWithDelayArgs = {
            newLoanId,
            prevLoanId: reactivation.prevLoanId,
            customerId: customer?.personReferenceNumber ?? '',
          }

          await completeReactivationMutation(application)
          setIsFinalizingDocuments(false)
        } else if (refi) {
          failRefinanceRequest()
        } else if (reactivation) {
          failReactivationRequest()
        } else {
          throw new Error()
        }
      }
    } catch {
      setIsCheckingForSignedDocuments(false)
      setIsFinalizingDocuments(false)
      if (refi) {
        failRefinanceRequest()
      } else if (reactivation) {
        failReactivationRequest()
      }
    }
  }

  /**
   * Navigates to prev screen
   */
  function goBackCallback() {
    history.goBack()
  }

  /**
   * Clears the cache and redirects the user back to the home screen
   */
  function goHomeCallback() {
    queryClient.clear()
    history.push('/home')
  }

  /**
   * Handles payment response and submits form if valid
   * @return function to unset the event listener
   */
  function handleIframeMessage() {
    const messageEventHandler = (e: MessageEvent) => {
      if (e.origin === adobeDocumentSignOrigin) {
        let documentWindow
        const messageData = JSON.parse(e.data) as AdobeMessageEvent
        if (e.source) {
          documentWindow = e.source as Window
        }

        trackIframeAnalytics(messageData)

        switch (messageData.type) {
          case 'ESIGN': {
            if (documentWindow !== undefined) {
              documentWindow.close()
            }
            checkSignedDocuments()
            break
          }
          case 'SESSION_TIMEOUT':
          case 'ERROR': {
            if (documentWindow !== undefined) {
              documentWindow.close()
            }
            handleAdobeSignError(messageData)
            break
          }
          default: {
            break
          }
        }
      }
    }
    window.addEventListener('message', messageEventHandler)

    return () => {
      window.removeEventListener('message', messageEventHandler)
    }
  }

  /**
   * Open the sign documents in a new tab
   */
  function openSignDocumentsCallback() {
    setIsSignedDocumentOpen(true)
    const url = getConfigValue('APP_URL')

    if (refi && refinanceCashbackApplicationData?.documentUrl) {
      const urlRedirect = `${refinanceCashbackApplicationData.documentUrl}&returnUrl=${url}${Routes.FINISH_SIGNATURE}`
      window.open(urlRedirect)
    }

    if (refi && refinanceApplicationData?.documentUrl) {
      const urlRedirect = `${refinanceApplicationData.documentUrl}&returnUrl=${url}${Routes.FINISH_SIGNATURE}`
      window.open(urlRedirect)
    }

    if (reactivationApplicationData?.documentUrl) {
      window.open(
        `${reactivationApplicationData.documentUrl}&returnUrl=${url}${Routes.FINISH_SIGNATURE}`,
        '_blank',
      )
    }
  }

  /**
   * Handles error cleanup from AdobeSign
   * @param messageData data from message
   */
  function handleAdobeSignError(messageData: AdobeMessageEvent) {
    // Show the AdobeSignErrorComponent
    setAdobeError(true)
    logErrorToApplicationInsights(messageData.data.message)

    pushToast({
      title:
        'An error occurred while signing documents. Please try again, and reach out to us if the error persists.',
      message: messageData.data.message,
      variant: 'danger',
    })
  }

  /**
   * Handles putting the successful reactivation or refinance data into the
   * queryCache and reroutes user to the success page
   * @param data Data returned from complete-reactivation or complete-refinance endpoints
   * @param queryString Specific query string to be used when storing the data
   */
  function navigateToSuccess(
    data: RefinanceLoanResponse | OriginateLoanResponse,
    queryString: string,
  ) {
    // If (data.responseCode !== 200 || data.loanId === 0) {
    //   if (reactivation) {
    //     if (checkForDocumentErrorInResponseMessage(data.message)) {
    //       pushToast({
    //         title: docErrorHeader,
    //         message: docErrorReactivationMessage,
    //         variant: 'danger',
    //       })
    //     }
    //     history.push('/reactivate-issue')
    //   } else {
    //     if (checkForDocumentErrorInResponseMessage(data.message)) {
    //       pushToast({
    //         title: docErrorHeader,
    //         message: docErrorRefiMessage,
    //         variant: 'danger',
    //       })
    //     }
    //     history.push('/refinance-issue')
    //   }
    //   return
    // }
    setSuccessfulResponse(data, queryString)
    if (reactivation) {
      history.push('/reactivation-thank-you')
    } else if (refi?.option === 'cashback') {
      history.push('/cashback-thank-you')
    } else {
      history.push('/refinance-thank-you')
    }
  }

  /**
   * Tracks specific events/exceptions based on the `messageData` param
   * type returned from the signDocument iFrame
   * @param messageData AbobeMessageEvent
   */
  function trackIframeAnalytics(messageData: AdobeMessageEvent): void {
    let eventName = ''
    let exceptionName = ''

    switch (messageData.type) {
      case 'ESIGN': {
        eventName = AnalyticsEventNames.SIGN_DOCUMENT_ESIGN
        break
      }
      case 'REJECT': {
        eventName = AnalyticsEventNames.SIGN_DOCUMENT_REJECT
        break
      }
      case 'ESIGN_SESSION_TIMEOUT': {
        exceptionName =
          AnalyticsExceptionNames.SIGN_DOCUMENT_ESIGN_SESSION_TIMEOUT
        break
      }
      case 'SESSION_TIMEOUT': {
        exceptionName = AnalyticsExceptionNames.SIGN_DOCUMENT_SESSION_TIMEOUT
        break
      }
      case 'ERROR': {
        exceptionName = AnalyticsExceptionNames.SIGN_DOCUMENT_ERROR
        break
      }
      default: {
        break
      }
    }

    if (eventName !== '') {
      appInsightsContext.trackEvent(
        {name: eventName},
        {name: messageData.data.message},
      )
    }

    if (exceptionName !== '') {
      appInsightsContext.trackException(
        {exception: Error(exceptionName)},
        {name: messageData.data.message},
      )
    }
  }
}

/**
 * Document sign and thankyou component in refinance flow
 */
export default ConfirmRefinance
